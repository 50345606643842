import React, { useEffect } from 'react';
import styled from 'styled-components';
import '../../styles/style.scss';
import { useTranslation } from 'react-i18next';
import { locales } from '../../components/base/LanguageSwitcher/index';

const Button = styled.button`
	display: none;
	width: 100%;
	padding: 10px;
	background-color: #fff;
	color: red;
	font-size: 14px;
	font-weight: 700;
	border: none;
	border-radius: 10px;
	cursor: pointer;
	transition: all 0.8s ease-in-out;
`;
const Card = styled.div`
	max-width: 400px;
	height: 490px;

	cursor: pointer;
	border-radius: 10px;

	overflow: hidden;
	padding: 26px 20px;
	display: flex;
	flex-direction: column;
	gap: 14px;
	transition: all 0.3s ease-in-out;
	&:hover {
		transform: translateY(-10px);
	}
	&:hover ${Button} {
		display: block;
	}
`;

const Image = styled.img`
	width: 100%;
	height: 180px;
	background-color: #5c62ec;
	border-radius: 10px;
	box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.3);
`;

const Tags = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 8px;
	margin-top: 4px;
	${'' /* min-height: 65px; */}
`;

const Tag = styled.span`
	font-size: 12px;
	font-weight: 700;
	color: var(--purple);
	background-color: rgba(92, 98, 236, 0.082);
	padding: 2px 8px;
	border-radius: 10px;
`;

const Details = styled.div`
	line-height: 1.6;

	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 0px;
	padding: 0px 2px;
`;
const Title = styled.div`
	font-size: 20px;
	font-weight: 600;
	overflow: hidden;
	display: -webkit-box;
	max-width: 100%;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Date = styled.div`
	font-size: 12px;
	margin-left: 2px;
	font-weight: 400;
	@media only screen and (max-width: 768px) {
		font-size: 10px;
	}
`;

const Description = styled.div`
	font-weight: 400;
	overflow: hidden;
	margin-top: 8px;
	display: -webkit-box;
	font-size: 16px;
	max-width: 100%;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
`;

const Members = styled.div`
	display: flex;
	align-items: center;
	padding-left: 10px;
`;
const Avatar = styled.img`
	width: 38px;
	height: 38px;
	border-radius: 50%;
	margin-left: -10px;
	background-color: ${({ theme }) => theme.white};
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	border: 3px solid ${({ theme }) => theme.card};
`;

const ProjectCards = ({ project, setOpenModal }) => {
	const { i18n } = useTranslation();
	const engLanguage = locales['en'].title;

	return (
		<Card
			className='projects__bg_card box__shadow '
			onClick={() => setOpenModal({ state: true, project: project })}
		>
			<Image src={process.env.PUBLIC_URL + '/' + project.image} alt='project image' />
			<Tags>
				{project.tags?.map((tag, index) => (
					<Tag key={index}>{tag}</Tag>
				))}
			</Tags>
			<Details className='project__color'>
				<Title>{project.title}</Title>
				<Date>{i18n.resolvedLanguage === engLanguage ? project?.dateEng : project?.dateRu}</Date>

				{/* {console.log(i18nextLng)} */}
				<Description>
					{i18n.resolvedLanguage === engLanguage ? project?.descriptionEng : project?.descriptionRu}
				</Description>
			</Details>
			{/* <Members>
				{project.member?.map((member) => (
					<Avatar src={member.img} />
				))}
			</Members> */}
			{/* <Button>View Project</Button> */}
		</Card>
	);
};

export default ProjectCards;
